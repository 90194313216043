<template>
  <div class="public-tracking-sidebar">
    <div class="public-tracking-sidebar--content">
      <v-form ref="form">
        <v-switch
          id="contract-public-tracking-enabled"
          v-model="publicTrackingEnabled"
          label="Live"
          class="live-switch"
          hide-details
          @change="canBeCopied = false"
        />
        <div row style="display: flex; align-items: center">
          <div style="padding-right: 5px">
            <CRIcon
              :view-box="`0 0 32 32`"
              :width="32"
              :color="
                publicTrackingEnabled && canBeCopied ? 'primary' : '#bababa'
              "
              :disabled="!publicTrackingEnabled || !canBeCopied"
              @click.native="() => copyUrl('hash')"
            >
              copy
            </CRIcon>
          </div>
          <div>
            <p class="display-vanity-url">
              {{ hashUrl }}
            </p>
          </div>
        </div>
        <CRInput
          id="contract-vanity-url"
          v-model="vanityUrl"
          :disabled="!publicTrackingEnabled"
          label="Vanity URL"
          placeholder="e.g. custom-url-for-contract"
          style="margin-top: 16px"
          @change="canBeCopied = false"
        />
        <div row style="display: flex; align-items: center">
          <div style="padding-right: 5px">
            <CRIcon
              :view-box="`0 0 32 32`"
              :width="32"
              :color="
                publicTrackingEnabled && canBeCopied ? 'primary' : '#bababa'
              "
              :disabled="!publicTrackingEnabled || !canBeCopied"
              @click.native="() => copyUrl('vanity')"
            >
              copy
            </CRIcon>
          </div>
          <div>
            <p class="display-vanity-url">
              {{ computedVanityUrl }}
            </p>
          </div>
        </div>
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="public-tracking-save-btn"
        :loading="submitting"
        class="public-tracking-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import contracts from '@/services/contracts'
import { authComputed } from '@/state/helpers'
import { environmentPrefix } from '@/utils/env'

export default {
  props: {
    contract: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      publicTrackingEnabled: false,
      vanityUrl: null,
      canBeCopied: false,
    }
  },
  computed: {
    ...authComputed,
    isModeAdd() {
      return !this.contract?.trackingUriMapId
    },
    isModeEdit() {
      return !!this.contract.trackingUriMapId
    },
    hashUrl() {
      return `https://${environmentPrefix()}.charterup.com/enterprise/tracking/${
        this.contract.hash
      }`
    },
    computedVanityUrl() {
      let inputUrl = this.vanityUrl
      if (!inputUrl) {
        inputUrl = 'custom-url-for-contract'
      }
      return `https://${environmentPrefix()}.charterup.com/enterprise/tracking/${inputUrl}`
    },
  },
  async mounted() {
    if (this.isModeEdit) {
      const res = await contracts.vanityUrlByUriMapId(
        this.contract.trackingUriMapId
      )
      this.vanityUrl = res.data?.vanityUrl || null
      this.publicTrackingEnabled = this.contract.trackingUriEnabled
      this.canBeCopied = true
    }
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    environmentPrefix,
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      const payload = {
        quoteId: this.contract.contractId,
        vanityUrlName: this.vanityUrl,
        enabled: this.publicTrackingEnabled,
      }
      if (this.isModeAdd) {
        try {
          const res = await contracts.createVanityUrl(payload)
          this.canBeCopied = true
        } catch (e) {
          this.showAlert({
            type: 'error',
            message: 'Error creating vanity url.',
          })
        }
      } else if (this.isModeEdit) {
        payload.uriMapId = this.contract.trackingUriMapId
        try {
          const res = await contracts.updateVanityUrl(payload)
          this.canBeCopied = true
        } catch (e) {
          this.showAlert({
            type: 'error',
            message: 'Error updating vanity url.',
          })
        }
      }
    },
    copyUrl(source) {
      if (source === 'hash') {
        navigator.clipboard.writeText(this.hashUrl)
        this.showAlert({
          type: 'success',
          message: 'URL copied to your clipboard.',
        })
      } else if (source === 'vanity') {
        navigator.clipboard.writeText(this.computedVanityUrl)
        this.showAlert({
          type: 'success',
          message: 'URL copied to your clipboard.',
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.public-tracking-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
.display-vanity-url {
  word-break: break-word;
  font-weight: 600;
  margin: 0;
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
